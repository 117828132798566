export const YupValidationMessages = {
  REQUIRED: "* @field is required.",
  INVALID: "* Invalid @field entered.",
  PASSWORD_LENGTH: "Password must be at least 6 characters",
};
export const RESEARCHER_ROLE = "Researcher";
export const OWNER_ROLE = "Owner";
export const SIGN_IN = {
  title: "Sign In",
  titleHeading: "Enter your email and password to Login",
  emailText: "Email",
  passwordText: "Password",
  forgotPassWordText: "Forgot your password?",
  forgotButtonLink: "Forgot Password",
};
export const backTitle = "Back";
export const ADD_USER = {
  title: "Add User",
  labels: {
    role: "Role",
    studies: "Studies",
    password: "Password",
    email: "Email",
    lastName: "Last Name",
    firstName: "First Name",
  },
  save: "Save",
  cancel: "Cancel",
};
export const EDIT_USER = {
  title: "Edit User",
};
export const PATH = {
  user: "users/",
  study: "studies/",
  participants: "participants/",
  chartData: "participantsData/@id/data/BasalLog/",
};
export const EMAIL_DOMAIN = "@euglide.com";

export const ROUTES = {
  addUser: "/users/add",
  editUser: "/users/edit",
  user: "/users",
};
export const SETTING_STORE_KEYS: string[] = [
  "isRTL",
  "color",
  "isAbsolute",
  "isNavFixed",
  "navbarFixed",
  "absolute",
  "showSidenav",
  "showNavbar",
  "showFooter",
  "showConfig",
  "hideConfigButton",
];
export const STORE_NAMES = {
  auth: "auth",
  setting: "settings",
  alert: "alert",
  study: "study",
  participant: "participant",
  chart: "chart",
};
export const COMPONENT_NAMES = {
  addEdit: "Add User",
  userList: "list",
  signIn: "sign-in",
};
export const ROLE_OPTIONS = [
  { label: "Select Role", value: "" },
  { label: "Owner", value: "Owner" },
  { label: "Researcher", value: "Researcher" },
];

export const MODAL_TITLE = {
  addTitle: "Add Study",
  EditTitle: "Edit Study",
};
export const STUDY_FILTERS = [
  { label: "All", value: "all" },
  { label: "Active", value: "active" },
  { label: "Completed", value: "completed" },
];
export const STUDY_STATUS = {
  All: "all",
  Active: "active",
  Completed: "completed",
};
export const RANDOM_STRING_LENGTH = 6;
export const ADD_PARTICIPANT_RESPONSE = {
  IdExist: "Id exist please try with new Id",
  Successfully: "Successfully Added",
  PinExist: "Pin exist please try to submit again",
  SomethingWentWrong: "Something Went Wrong please try after sometime",
};
export const STUDY_RESPONSE = {
  NO_STUDY: "No study exists",
  SOMETHING_WENT_WRONG: "Something Went Wrong please try after sometime",
};
/**
 * for participant detail page
 */
export const DEMOGRAPHICS = [
  { label: "Age", key: "dob" },
  { label: "Sex", key: "gender" },
  { label: "Duration of Diabetes", key: "diagnoseDate" },

  { label: "Height", key: "height" },
  { label: "Weight", key: "weight" },
  { label: "HbA1C", key: "bloodTest" },
];
export const SCHEDULES = [
  { label: "Admission", key: "admissionDate" },
  { label: "End of study", key: "studyEndDate" },
];
export const TAB_VALUES = {
  Overview: "Overview",
  Continuous: "Continous",
  Stacked: "Stacked",
  Daily: "Daily",
};
export const DAYS_RANGE = {
  Day: 1,
  Week: 7,
};
export const TABLE_HEADERS = [
  "#",
  "Intervention",
  "TIR(%)",
  "<3.9%",
  ">10%",
  "TCL(%)",
  "HbA1c%",
];

export const zeroPercent = "0%";
export const zeroValue = 0;
export const SELF_CONSUMED_BOLUS_TYPES = {
  Meal: "MEAL_BOLUS",
  Correction: "CORRECTION_BOLUS",
};
