import {
  IBasalData,
  IChartDataResponse,
  IData,
} from "@/utils/Interfaces/Chart";
import moment from "moment-timezone";
import { calcPercentage, generateLabels } from "./Common";
import { zeroPercent } from "./Constants";
const zoomOptions = {
  zoom: {
    wheel: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: "xy",
  },
  pan: {
    enabled: true,
    mode: "xy",
  },
};

const skipped = (ctx: any, value: any) =>
  ctx.p0.skip || ctx.p1.skip ? value : undefined;

const getStartTime = (
  startTimeStamp: number,
  interval: number,
  date: number
): number => {
  if (startTimeStamp - interval <= date) {
    return startTimeStamp;
  }
  return getStartTime(startTimeStamp - interval, interval, date);
};

const getEndTime = (
  startTimeStamp: number,
  interval: number,
  date: number
): number => {
  if (startTimeStamp + interval >= date) {
    return startTimeStamp;
  }
  return getEndTime(startTimeStamp + interval, interval, date);
};

const calculateTime = (
  basalData: Array<{
    x: number;
    y: number;
  }>
) => {
  try {
    const [{ x: timestamp1 }, { x: timestamp2 }] = basalData;
    const interval = timestamp2 - timestamp1;

    const date = moment(timestamp1).startOf("day").valueOf();
    const newDate = moment(timestamp1).endOf("day").valueOf();

    return {
      startTimeStamp: getStartTime(timestamp1, interval, date),
      endTimeStamp: getEndTime(timestamp2, interval, newDate),
    };
  } catch (err) {
    if (err) throw err;
    throw new Error();
  }
};

const sortedGlucoseData = (GlucoseData: IData[]) => {
  try {
    return GlucoseData.filter((data) => data.basalData).map((data) => ({
      basalData: Object.values(data.basalData).sort(
        (a, b) => a.requestedAt - b.requestedAt
      ),
    }));
  } catch (err) {
    if (err) throw err;
    throw new Error();
  }
};
interface BasalDataItem {
  x: number;
  y: number;
}

const newSortedGlucoseData = (GlucoseData: IData[]) => {
  try {
    const { basalData } = GlucoseData[0];
    if (basalData) {
      const convertDesiredOutput = GlucoseData.filter(
        (data) => data.basalData
      ).map((data) => ({
        basalData: Object.values(data.basalData)
          .reduce((acc, { requestedAt, bloodGlucose }) => {
            const a = moment(requestedAt);
            const zeroSeconds = a.seconds(0).milliseconds(0);
            acc.push(
              bloodGlucose > 0
                ? {
                    x: zeroSeconds.valueOf(),
                    y: bloodGlucose,
                  }
                : {
                    x: zeroSeconds.valueOf(),
                    y: NaN,
                  }
            );
            return acc;
          }, [])
          .sort((a: BasalDataItem, b: BasalDataItem) => a.x - b.x),
      }));

      const convertedDatesArray = insertMinutesGlucose(
        JSON.parse(JSON.stringify(convertDesiredOutput[0].basalData))
      );
      let clonedArray = JSON.parse(JSON.stringify(convertedDatesArray));

      for (let i = 0; i < clonedArray.length; i++) {
        if (clonedArray[i].y === null || isNaN(clonedArray[i].y)) {
          let j = getNextValidIndex(clonedArray, i);
          if (
            j < clonedArray.length &&
            clonedArray[i - 1]?.y &&
            clonedArray[j].y
          ) {
            let start = clonedArray[i - 1].y;
            let end = clonedArray[j].y;
            let startTime = clonedArray[i - 1].x;
            let endTime = clonedArray[j].x;
            let slope = (end - start) / (endTime - startTime);
            if (endTime - startTime <= 3600000) {
              for (let k = i; k < j; k++) {
                let time = clonedArray[k].x;
                clonedArray[k].y = Number(
                  (start + slope * (time - startTime)).toFixed(1)
                );
              }
            }
          }
        }
      }

      const startOfTime = moment(clonedArray[0].x).startOf("day").valueOf();

      const endOfTime = moment(clonedArray[clonedArray.length - 1].x)
        .endOf("day")
        .valueOf();

      const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

      // Function to check if a timestamp falls within a day
      const isTimestampWithinDay = (
        timestamp: number,
        dayStartTime: number
      ) => {
        return (
          timestamp >= dayStartTime &&
          timestamp < dayStartTime + oneDayInMilliseconds
        );
      };

      // Function to split the array day-wise
      const splitArrayByDay = (
        arr: { x: number; y: number }[],
        startTimestamp: number,
        endTimestamp: number
      ) => {
        const result = [];
        let currentDayStartTime = startTimestamp;

        while (currentDayStartTime < endTimestamp) {
          // added unique timestamp logic here for duplicate enteries
          const uniqueTimestamps = new Set();
          const currentDaySubarray = arr.filter(({ x }) => {
            if (
              isTimestampWithinDay(x, currentDayStartTime) &&
              !uniqueTimestamps.has(x)
            ) {
              uniqueTimestamps.add(x);
              return true;
            }
            return false;
          });
          result.push({ basalData: currentDaySubarray });
          currentDayStartTime += oneDayInMilliseconds;
        }

        return result;
      };

      const dayWiseSplit = splitArrayByDay(clonedArray, startOfTime, endOfTime);
      const { startTimeStamp, endTimeStamp } = calculateTime(
        dayWiseSplit[0]?.basalData
      );
      const labels = generateLabels(startTimeStamp);
      return {
        ...countMean(dayWiseSplit, startTimeStamp, endTimeStamp),
        labels,
      };
    }
    return {
      means: [],
      median: [],
      iqr1: [],
      iqr2: [],
      labels: [],
    };
  } catch (err) {
    return {
      means: [],
      median: [],
      iqr1: [],
      iqr2: [],
      labels: [],
    };
  }
};

const Array_Sort_Numbers = (inputArray: Array<number>) => {
  return inputArray.sort(function (a, b) {
    return a - b;
  });
};

const Quartile = (data: Array<number>, q: number) => {
  let data1 = Array_Sort_Numbers(data);
  var pos = (data1.length - 1) * q;
  var base = Math.floor(pos);
  var rest = pos - base;
  if (data1[base + 1] !== undefined) {
    return data1[base] + rest * (data1[base + 1] - data1[base]);
  } else {
    return data1[base];
  }
};

const calculateFiveNumberSummary = (data: any) => {
  // Sort the data in ascending order
  const sortedData = data.sort((a: any, b: any) => a - b);

  // Calculate the median
  const medianIndex = Math.floor(sortedData.length / 2);
  const medianNew =
    sortedData.length % 2 === 0
      ? (sortedData[medianIndex - 1] + sortedData[medianIndex]) / 2
      : sortedData[medianIndex];

  // Calculate the first and third quartiles
  const q1 = Quartile(data, 0.25);
  const q3 = Quartile(data, 0.75);

  // Calculate the mean
  const sum = sortedData.reduce((acc: any, val: any) => acc + val, 0);
  const mean = sum / sortedData.length;

  return {
    medianNew,
    mean,
    q1,
    q3,
  };
};

const countMean = (
  GlucoseData: { basalData: { x: number; y: number }[] }[],
  startTimestamp: number,
  endTimestamp: number
) => {
  try {
    const oneDayInterval = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    let means: { x: string | Date; y: number | null }[] = [];
    let median: { x: string | Date | number; y: number | null }[] = [];
    let iqr1: { x: string | Date; y: number | null }[] = [];
    let iqr2: { x: string | Date; y: number | null }[] = [];
    for (let i = startTimestamp; i < endTimestamp; i += 60000) {
      let medianTotal: (number | null)[] = [];
      let localStartTimeStamp = i;
      for (let j = 0; j < GlucoseData.length; j++) {
        const basalData: { x: number; y: number }[] = GlucoseData[j].basalData;
        for (const value of basalData) {
          const { x, y } = value;
          if (x >= localStartTimeStamp && x <= localStartTimeStamp + 55000) {
            if (y !== null) medianTotal.push(y);
            break;
          }
        }
        localStartTimeStamp += oneDayInterval;
      }

      const { medianNew, mean, q1, q3 } = calculateFiveNumberSummary(
        medianTotal
      );

      median.push({
        y: medianNew || null,
        x: moment(i).toISOString(),
      });
      means.push({
        y: Number(mean?.toFixed(2)) || null,
        x: moment(i).toISOString(),
      });
      iqr1.push({
        y: Number(q1?.toFixed(2)) || null,
        x: moment(i).toISOString(),
      });
      iqr2.push({
        y: Number(q3?.toFixed(2)) || null,
        x: moment(i).toISOString(),
      });
    }
    return {
      means,
      median,
      iqr1,
      iqr2,
    };
  } catch (err) {
    if (err) throw err;
    throw new Error();
  }
};

const calcOverViewOutcomes = (
  sortedData: Array<{ basalData: IBasalData[] }>
) => {
  let tclCount = 0;
  let belowRangeCount = 0;
  let aboveRangeCount = 0;
  let tirCount = 0;
  let totalSum = 0;
  sortedData.forEach(({ basalData }, idx) => {
    if (basalData.length) {
      basalData.forEach(({ bloodGlucose, autoMode }) => {
        if (bloodGlucose > 0) totalSum++;
        if (autoMode && bloodGlucose > 0) tclCount++;
        if (bloodGlucose < 3.9 && bloodGlucose > 0) belowRangeCount++;
        if (bloodGlucose > 10) aboveRangeCount++;
        if (bloodGlucose >= 3.9 && bloodGlucose <= 10) tirCount++;
      });
    }
  });

  let tclPercentage = calcPercentage(tclCount, totalSum) || zeroPercent;
  let belowRangePercentage =
    calcPercentage(belowRangeCount, totalSum) || zeroPercent;
  let aboveRangePercentage =
    calcPercentage(aboveRangeCount, totalSum) || zeroPercent;
  let tirPercentage = calcPercentage(tirCount, totalSum) || zeroPercent;
  return {
    tclPercentage,
    belowRangePercentage,
    aboveRangePercentage,
    tirPercentage,
  };
};

const getNextValidIndex = (
  array: {
    y: number;
    x: Date | string;
  }[],
  index: number
) => {
  let j = index + 1;
  while (j < array.length && (array[j].y === null || isNaN(array[j].y))) {
    j++;
  }
  return j;
};

function insertMinutesGlucose(
  arrayData: {
    y: number;
    x: number;
  }[]
) {
  const convertedDatesArray = [];
  for (let i = 0; i < arrayData.length; i++) {
    convertedDatesArray.push(arrayData[i]);
    const nextObj = arrayData[i + 1];

    if (nextObj) {
      const timeDiff: number = nextObj.x - arrayData[i].x;
      if (timeDiff > 60000) {
        let newTime = arrayData[i].x + 60000;
        const newObjTime = nextObj.x;

        while (newTime < newObjTime) {
          convertedDatesArray.push({
            x: newTime,
            y: null,
          });
          newTime = newTime + 60000;
        }
      }
    }
  }
  return convertedDatesArray;
}

/**
 * calculate interpolated values
 * @param arrayData
 * @returns
 */

export function interPolatedValues(
  arrayData: {
    y: number;
    x: string | Date | number;
  }[]
) {
  try {
    const convertedDatesArray = insertMinutesGlucose(
      JSON.parse(JSON.stringify(arrayData))
    );
    let clonedArray = JSON.parse(JSON.stringify(convertedDatesArray));
    for (let i = 0; i < clonedArray.length; i++) {
      if (clonedArray[i].y === null || isNaN(clonedArray[i].y)) {
        let j = getNextValidIndex(clonedArray, i);
        if (
          j < clonedArray.length &&
          clonedArray[i - 1]?.y &&
          clonedArray[j].y
        ) {
          let start = clonedArray[i - 1].y;
          let end = clonedArray[j].y;
          let startTime = clonedArray[i - 1].x;
          let endTime = clonedArray[j].x;
          let slope = (end - start) / (endTime - startTime);
          if (endTime - startTime <= 3600000) {
            for (let k = i; k < j; k++) {
              let time = clonedArray[k].x;
              clonedArray[k].y = Number(
                (start + slope * (time - startTime)).toFixed(1)
              );
            }
          }
        }
      }
    }
    return clonedArray;
  } catch (err) {
    if (err) throw err;
    throw new Error();
  }
}

/**
 * calculate overview Data
 * @param GlucoseData
 * @param participantDetail
 * @returns
 */
export const calcOverviewData = (GlucoseData: IData[]): IChartDataResponse => {
  try {
    const newObj = sortedGlucoseData(GlucoseData);
    const outcomes = calcOverViewOutcomes(newObj);

    return {
      ...outcomes,
    };
  } catch (err) {
    if (err) throw err;
    throw new Error();
  }
};

export const calcOverviewChartData = (GlucoseData: IData[]) => {
  return newSortedGlucoseData(GlucoseData);
};

export const getBasal = (data: { x: string; y: number }[] | number[]) => {
  return {
    yAxisID: "insulin",
    label: "Closed loop basal",
    backgroundColor: ["#74b766"],
    borderColor: ["#74b766"],
    data,
    barThickness: 3,
    borderWidth: 2,
    type: "bar",
    pointStyle: "circle",
    pointRadius: 2,
  };
};

export const getBolus = (
  data:
    | {
        x: string;
        y: number;
        icr: string;
        bolusAmount: number;
        iob: string;
        algoTotalBolus: string;
        // mealCarbAmount: number;
      }[]
    | number[]
) => {
  return {
    yAxisID: "insulin",
    label: "Bolus-Algo",
    backgroundColor: "rgba(49, 245, 238, 1)",
    borderColor: "rgba(49, 245, 238, 1)",
    data,
    barThickness: 5,
    borderWidth: 2,
    type: "bar",
    pointStyle: "circle",
    fill: true,
    pointRadius: 2,
    datalabels: {
      color: "blue",
      anchor: "end",
      align: "top",
    },
  };
};

export const getSelfConsumedBolus = (
  data:
    | {
        x: string;
        y: number;
        icr: string;
        bolusAmount: number;
        iob: string;
        algoTotalBolus: string;
        // mealCarbAmount: number;
      }[]
    | number[]
) => {
  return {
    yAxisID: "insulin",
    label: "Bolus",
    backgroundColor: "rgba(255, 0, 0)",
    borderColor: "rgba(255, 0, 0)",
    data,
    barThickness: 5,
    borderWidth: 2,
    type: "bar",
    pointStyle: "circle",
    fill: true,
    pointRadius: 2,
    datalabels: {
      color: "blue",
      anchor: "end",
      align: "top",
    },
  };
};

export const config = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    zoom: zoomOptions,
    datalabels: {
      formatter: function (value: any, context: any) {
        if (context.datasetIndex === 5) {
          return value.mealCarbAmount;
        } else if (context.datasetIndex === 4 && value.mode) {
          return "🏃";
        } else {
          return null;
        }
      },
    },
    legend: {
      position: "bottom",
      display: true,
      labels: {},
    },
    mode: "nearest",
    tooltip: {
      intersect: false,
      mode: "nearest",
      xPadding: 20,
      yPadding: 15,
      cornerRadius: 10,
      titleFontSize: 20,
      titleFontColor: "#000",
      bodyFontColor: "#555",
      bodyFontStyle: "bold",
      bodyFontSize: 13,
      footerFontColor: "#777",
      footerFontSize: 12,
      footerFontStyle: "normal",
      displayColors: false,
      caretPadding: 10,
      callbacks: {
        beforeTitle: function (tooltipItem: any) {
          if (tooltipItem[0].datasetIndex === 0) {
            return `Blood Glucose:- ${tooltipItem[0]?.raw?.y}mmol/L`;
          }
          if (tooltipItem[0].datasetIndex === 1) {
            return `Closed loop basal:- ${tooltipItem[0]?.raw?.y} U/hr`;
          }
          if (tooltipItem[0].datasetIndex === 2) {
            return `Bolus-Algo:- ${tooltipItem[0]?.raw?.bolusAmount?.toFixed(
              2
            )}`;
          }
          if (tooltipItem[0].datasetIndex === 3) {
            return `Open loop basal:- ${tooltipItem[0]?.raw?.y}`;
          }
          if (tooltipItem[0].datasetIndex === 6) {
            return `Bolus:- ${tooltipItem[0]?.raw?.bolusAmount?.toFixed(2)}`;
          }
        },
        beforeLabel: function (tooltipItem: any) {
          if (
            tooltipItem.datasetIndex === 2 ||
            tooltipItem.datasetIndex === 6
          ) {
            return `IOB:- ${tooltipItem.raw.iob?.toFixed(2)}`;
          }
        },
        footer: function (tooltipItem: any) {
          if (
            tooltipItem[0].datasetIndex === 2 ||
            tooltipItem[0].datasetIndex === 6
          ) {
            return `${moment
              .utc(tooltipItem[0].label)
              .format("MMMM Do YYYY, h:mm a")}`;
          }
        },
        title: function (tooltipItem: any) {
          if (
            tooltipItem[0].datasetIndex === 2 ||
            tooltipItem[0].datasetIndex === 6
          ) {
            if (tooltipItem[0].raw.mealCarbAmount > 0) {
              return `ICR:- ${tooltipItem[0].raw.icr?.toFixed(2)}`;
            }
            return null;
          }
          return `${moment
            .utc(tooltipItem[0].label)
            .format("MMMM Do YYYY, h:mm a")}`;
        },
        label: function (tooltipItem: any) {
          if (
            tooltipItem.datasetIndex === 2 ||
            tooltipItem.datasetIndex === 6
          ) {
            return `Meal Carb Amount:- ${tooltipItem.raw.mealCarbAmount?.toFixed(
              2
            )}`;
          }
          return null;
        },
      },
    },
    annotation: {
      annotations: {
        box1: {
          type: "box",
          yMax: 10,
          yMin: 3.9,
          yScaleID: "bg",
          borderWidth: 1,
          borderColor: "rgba(0,0,0,0)",
          backgroundColor: "rgba(48, 76, 156, 0.15)",
        },
      },
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "hour",
        displayFormats: {
          hour: "HH",
        },
      },
      title: {
        color: "#344767",
        display: true,
        text: "Time/Hour",
        align: "center",
      },
      ticks: {
        source: "auto",
        display: true,
        maxRotation: 0,
        minRotation: 0,
        align: "center",
      },
      offset: false,
      grid: {
        drawBorder: true,
        drawOnChartArea: false,
        drawTicks: true,
      },
      font: {
        size: 8,
      },
      stacked: true,
    },
    bg: {
      position: "left",
      title: {
        color: "#344767",
        display: true,
        text: "Blood Glucose (mmol/L)",
        align: "center",
      },
      grid: {
        drawOnChartArea: true,
      },
      min: 0,
      max: 22.3,
      ticks: {
        stepSize: 2,
      },
    },
    chartType: "line",
    insulin: {
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
      title: {
        color: "#344767",
        display: true,
        text: "Insulin Dose (U/hr)",
        align: "center",
      },
      min: 0,
      max: 16,
      beginAtZero: true,
      ticks: {
        stepSize: 2,
      },
    },
  },
};

export const overviewConfig = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    zoom: zoomOptions,
    datalabels: {
      formatter: function (value: any, context: any) {
        return null;
      },
    },
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
    tooltip: {
      intersect: false,
      mode: "nearest",
      xPadding: 20,
      yPadding: 15,
      cornerRadius: 10,
      titleFontSize: 20,
      titleFontColor: "#000",
      bodyFontColor: "#555",
      bodyFontStyle: "bold",
      bodyFontSize: 13,
      footerFontColor: "#777",
      footerFontSize: 12,
      footerFontStyle: "normal",
      displayColors: false,
      caretPadding: 10,
      callbacks: {
        title: function (tooltipItem: any) {
          return `${moment.utc(tooltipItem[0].label).format("h:mm a")}`;
        },

        label: function (tooltipItem: any) {
          return `${tooltipItem?.dataset?.label}- ${tooltipItem?.raw?.y}`;
        },
      },
    },
    mode: "nearest",
    hover: {
      mode: "nearest",
      intersect: true,
    },
    annotation: {
      annotations: {
        box1: {
          type: "box",
          yMax: 10,
          yMin: 3.9,
          yScaleID: "bg",
          borderWidth: 1,
          borderColor: "rgba(0,0,0,0)",
          backgroundColor: "rgba(48, 76, 156, 0.15)",
        },
      },
    },
  },
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      display: true,
      type: "time",
      align: "start",
      time: {
        unit: "hour",
        displayFormats: {
          hour: "HH",
        },
      },
      title: {
        color: "#344767",
        display: true,
        text: "Time/Hour",
        align: "center",
      },
      offset: false,
      distribution: "linear",
      grid: {
        drawBorder: true,
        drawOnChartArea: false,
        drawTicks: true,
      },
      ticks: {
        source: "auto",
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0,
        beginAtZero: true,
      },
      stacked: true,
    },
    bg: {
      id: "bg",
      type: "linear",
      position: "left",
      beginAtZero: true,
      scaleLabel: {
        display: true,
        labelString: "Blood Glucose (mmol/L)",
      },
      offset: false,

      ticks: {
        beginAtZero: true,
        max: 22.3,
        min: 0,
        stepSize: 2,
        source: "data",
        autoSkip: false,
        align: "center",
      },
    },
  },
};

export const label = [];

export const overViewConfigData = [
  {
    label: "Mean",
    borderWidth: 5,
    pointRadius: 0,
    backgroundColor: "rgb(76, 175, 80)",
    borderColor: "rgb(76, 175, 80)",
  },
  {
    label: "Median",
    pointRadius: 0,
    backgroundColor: "rgba(11, 66, 193, 1)",
    borderColor: "rgba(11, 66, 193, 1)",
  },
  {
    label: "IQR1 (25%)",
    pointRadius: 0,
    backgroundColor: "rgba(48, 76, 156, 0.3)",
    borderColor: "rgba(48, 76, 156, 0.3)",
  },
  {
    label: "IQR3 (75%)",
    pointRadius: 0,
    backgroundColor: "rgba(52, 71, 103, 1)",
    borderColor: "rgba(52, 71, 103, 1)",
  },
];

export const getGlucoseConfig = (
  data: Array<{ x: string; y: number } | number> | number[]
) => {
  return {
    yAxisID: "bg",
    type: "line",
    label: "Glucose",
    data,
    fill: false,
    backgroundColor: "#18264f",
    borderColor: "#18264f",
    tension: 0.3,
    pointStyle: "circle",
    pointRadius: 2,
    borderWidth: 2,
    spanGaps: true,
    segment: {
      borderDash: (ctx: any) => skipped(ctx, [6, 6]),
    },
  };
};

export const getExerciseConfig = (
  data: Array<{ x: string | null; y: number | null } | null> | number[]
) => {
  return {
    yAxisID: "insulin",
    type: "line",
    label: "Exercise",
    data,
    fill: false,
    backgroundColor: "#e0d428",
    borderColor: "#e0d428",
    tension: 0,
    pointStyle: "rect",
    pointRadius: 2,
    borderWidth: 2,
    spanGaps: false,
    datalabels: {
      anchor: "end",
      align: "top",
    },
  };
};

export const getMealCarbPointer = (
  data: Array<{
    x: number;
    y: number;
    mealCarbAmount: number;
  }>
) => {
  return {
    yAxisID: "bg",
    type: "line",
    label: "MealCarbPointer",
    data,
    fill: false,
    backgroundColor: "#4287f5",
    borderColor: "#4287f5",
    tension: 0,
    pointStyle: "triangle",
    rotation: 60,
    pointRadius: 2,
    borderWidth: 2,
    spanGaps: false,
    showLine: false,
    datalabels: {
      color: "#41CDC8",
      anchor: "end",
      clamp: true,
      align: "top",
      offset: -2,
      font: {
        size: 9,
      },
    },
  };
};

export const getOpenLoopBasal = (
  data: Array<{ x: string; y: number } | number> | number[]
) => {
  return {
    yAxisID: "insulin",
    type: "line",
    label: "Open Loop Basal",
    data,
    backgroundColor: "rgba(11, 66, 193, 1)",
    borderColor: "rgba(11, 66, 193, 1)",
    tension: 0.3,
    pointStyle: "circle",
    pointRadius: 1,
    borderWidth: 2,
    spanGaps: true,
  };
};
